<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../assets/images/logo/logo-symbol-light.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Najnowsze artykuły</h2>
              <ul class="page-list">
                <li><router-link to="/">Strona główna</router-link></li>
                <li class="current-page">Blog</li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start blog Area  -->
    <div class="rn-blog-area rn-section-gap bg_color--1">
      <v-container>
        <BlogList />

<!-- @TODO paginacja -->
<!--        <v-row>-->
<!--          <v-col lg="12">-->
<!--            <div class="rn-pagination text-center mt&#45;&#45;60 mt_sm&#45;&#45;30">-->
<!--              <Pagination />-->
<!--            </div>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-container>
    </div>
    <!-- Start blog Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import BlogList from "../../components/blog/BlogList";
  import Pagination from "../../components/pagination/Pagination";
  import Footer from "../../components/footer/Footer";

  export default {
    components: {
      Header,
      BlogList,
      Pagination,
      Footer,
    },
    data() {
      return {};
    },

    methods: {},
  };
</script>
